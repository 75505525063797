<template>
  <div class="container-fluid">
    <div class="row" v-if="hamla == ''">
        <div class="col-12">
            <br><br><br><br>
        </div>
        <div class="col-12 col-lg-6 g text-right" v-if="hamla_1">
            <button class="btn btn-lg btn-primary" @click="hamla = '1'; getTrips()">
                الباقة 2
            </button>
        </div>
        <div class="col-12 col-lg-6 g text-left" v-if="hamla_2">
            <button class="btn btn-lg btn-success" @click="hamla = '2'; getTrips()">
                الباقة 4
            </button>
        </div>
    </div>
    <b-modal id="send" title="ارسال لمن لم يركب" size="lg" hide-footer>
        <div class="form-group">
          <h5 for="">الرسالة</h5>
          <textarea class="form-control" v-model="message" rows="3"></textarea>
        </div>
        <div class="col-12 g text-center">
            <button class="btn btn-success" @click="sendNow()">
                <i class="fa fa-whatsapp"></i>
                ارسال الان بالواتس الذكي
            </button>
        </div>
        <br>
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        <div class="form-check">
                          <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" @click="selected.length ? selected = [] : selected = trips.map(function(x){return x._id})">
                            تحديد الكل
                          </label>
                        </div>
                    </th>
                    <th>
                        رقم الرحلة
                    </th>
                    <th>
                        الحافلة
                    </th>
                    <th>
                        المشرف
                    </th>
                    <th>
                        من لم يركب
                    </th>
                </thead>
                <tbody>
                    <tr v-for="trip in trips" :key="trip._id">
                        <td>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" v-model="selected" :value="trip._id">
                                اختيار
                              </label>
                            </div>
                        </td>
                        <td>
                            {{ trip._id.substring(10,16) }}
                        </td>
                        <td>
                            {{ trip.bus.title }}
                        </td>
                        <td>
                            {{ trip.admin.name }}
                        </td>
                        <td>
                            {{ trip.users_left.length }}
                            <button class="btn btn-sm btn-secondary" @click="currentUsers = trip.users_left; tt = `من لم يركب الحافلة ${trip.bus.title}`" v-b-modal.edit><i class="fa fa-eye"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
    <div class="col-12" v-if="hamla != ''">
        <div class="card">
            <div class="card-header">
                <h5>
                    الرحلات (
                            {{ hamla == '1' ? 'الباقة 2' : 'الباقة 4' }}
                        ) &nbsp;
                    <button class="btn btn-success btn-sm" @click="startAll()">
                        <i class="fa fa-rocket"></i>
                        بدء كل الرحلات
                    </button>&nbsp;
                    <button class="btn btn-primary btn-sm" @click="endAll()">
                        <i class="fa fa-times"></i>
                        انهاء كل الرحلات
                    </button>&nbsp;
                    <button class="btn btn-danger btn-sm" v-b-modal.send>
                        <i class="fa fa-whatsapp"></i>
                        ارسال لمن لم يركب
                    </button>
                </h5>
            </div>
            <div class="card-footer">
                <div class="col-12 table-responsive">
                    <table class="table table-hover table-sm table-bordered">
                        <thead>
                            <th>
                                رقم الرحلة
                            </th>
                            <th>
                                الحافلة
                            </th>
                            <th>
                                المشرف
                            </th>
                            <th>
                                النوع
                            </th>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                لم يركب
                            </th>
                            <th>
                                ركب الحافلة
                            </th>
                            <th>
                                تنبيه
                            </th>
                            <th>
                                الحالة
                            </th>
                            <th>
                                خيارات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="trip in trips" :key="trip._id">
                            <td>
                                {{ trip._id.substring(10,16) }}
                            </td>
                            <td>
                                {{ trip.bus.title }}
                            </td>
                            <td>
                                {{ trip.admin.name }}
                            </td>
                            <td>
                                {{ trip.type }}
                            </td>
                            <td>
                                {{ trip.date.split(" ")[0] }}
                            </td>
                            <td>
                                {{ trip.users_left.length }}
                                <button class="btn btn-sm btn-secondary" @click="currentUsers = trip.users_left; tt = `من لم يركب الحافلة ${trip.bus.title}`" v-b-modal.edit><i class="fa fa-eye"></i></button>
                            </td>
                            <td>
                                {{ trip.users_done.length }}
                                <button class="btn btn-sm btn-secondary" @click="currentUsers = trip.users_done; tt = `من ركب الحافلة ${trip.bus.title}`" v-b-modal.edit><i class="fa fa-eye"></i></button>
                            </td>
                            <td>
                                {{ trip.alerts.length }}
                                <button class="btn btn-sm btn-secondary" @click="currentUsers = trip.alerts; tt = `تنبيهات بركوب الحافلة ${trip.bus.title}`" v-b-modal.edit><i class="fa fa-eye"></i></button>
                            </td>
                            <td>
                                <span class="btn btn-sm btn-success btn-block" style="border-radius: 0px" v-if="!trip.status">
                                    جارية
                                </span>
                                <span class="btn btn-sm btn-primary btn-block" style="border-radius: 0px" v-if="trip.status">
                                    انتهت
                                </span>
                            </td>
                                <td>
                                    <b-dropdown dropleft style="width: 100%;"
                                        id="dropdown-1x"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        text="خيارات"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b-dropdown-item @click="endTrip(trip._id)" v-if="!trip.status">
                                            <i class="fa fa-times"></i>
                                            انهاء الرحلة
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="deleteTrip(trip._id)">
                                            <i class="fa fa-trash"></i>
                                            حذف الرحلة
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <b-modal id="edit" title="عرض" hide-footer>
            <button class="btn btn-sm btn-warning" @click="printeb()">طباعة</button>
            <br><br>
            <div class="col-12 table-responsive ep" v-if="currentUsers.length">
                <h5 class="dnone">{{ tt }}</h5>
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th v-if="currentUsers[0].sholud_be_in_title">
                            الحافلة المفترضة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="u in currentUsers" :key="u.phone">
                            <td>
                                {{ u.name ? u.name : users[u].name }}
                            </td>
                            <td>
                                {{ u.phone ? u.phone : users[u].phone }}
                            </td>
                            <td v-if="u.sholud_be_in_title">
                                {{ u.sholud_be_in_title }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
  </div>
</template>


<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            trips: [],
            hamla: "",
            currentUsers: [],
            message: "",
            tt: "",
            user: JSON.parse(localStorage.getItem("user")),
            create: {},
            selected: [],
            hamla_1: checkPer("hamla_1"),
            hamla_2: checkPer("hamla_2"),
            users: {}
        }
    },
    created(){
        if(!checkPer('trips')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        $.post(api + '/admin/users/obj', {
            jwt: g.user.jwt
        }).then(function(r){
            g.users = r.response;
        })
    },
    methods: {
        getTrips(){
            var g = this;
            $.post(api + '/admin/trips/list', {
                jwt: g.user.jwt,
                hamla: g.hamla
            }).then(function(r){
                g.trips = r.response;
            })
        },
        endTrip(id){
            var g = this;
            if(confirm("متاكد من انهاء الرحلة؟")){
                $.post(api + '/admin/trips/end', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    if(r.status == 200){
                        return alert(r.response, 200)
                    }
                    g.getTrips()
                    alert("تم بنجاح", 100)
                })
            }
        },
        deleteTrip(id){
            var g = this;
            if(confirm("متاكد من حذف الرحلة؟")){
                $.post(api + '/admin/trips/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    if(r.status == 200){
                        return alert(r.response, 200)
                    }
                    g.getTrips()
                    alert("تم بنجاح", 100)
                })
            }
        },
        startAll(){
            var g = this;
            if(confirm("متاكد من بدء كل الرحلات؟")){
                var type = prompt("نوع الرحلة", "")
                if(type && type != ""){
                    $.post(api + '/admin/trips/start-all', {
                        jwt: g.user.jwt,
                        hamla: g.hamla,
                        type: type
                    }).then(function(r){
                        if(r.status == 200){
                            return alert(r.response, 200)
                        }
                        g.getTrips()
                        alert("تم بنجاح", 100)
                    })
                }
            }
        },
        endAll(){
            var g = this;
            if(confirm("متاكد من انهاء كل الرحلات؟")){
                $.post(api + '/admin/trips/end-all', {
                    jwt: g.user.jwt
                }).then(function(r){
                    if(r.status == 200){
                        return alert(r.response, 200)
                    }
                    g.getTrips()
                    alert("تم بنجاح", 100)
                })
            }
        },
        printeb(){
            var divToPrint = $(".ep");
            var newWin= window.open("");
            newWin.document.write(`
            <style>
            table, td, th {
                border: 1px solid;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            html,body{
                direction: rtl
            }
        tr:nth-of-type(20n){
            page-break-after: always;
        }
        .x{
           position: relative;
           top: 100px
        }
        h5{
            position: absolute;
            top: -50px;
            right: 10px
        }
        .banner{
            width: 100%;
            position: fixed;
            top: 0px
        }
        .footer{
            width: 100%;
            position: fixed;
            bottom: 0px
        }
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        @media print and (orientation: portrait) {
            .banner2{
                display: none
            }
            .footer2{
                display: none
            }
        }
        @media print and (orientation: landscape) {
            .banner{
                display:none
            }
            .footer{
                display: none
            }
            .banner2{
                width: 100%;
                position: fixed;
                top: 0px
            }
            .footer2{
                width: 100%;
                position: fixed;
                bottom: 0px
            }
        }
            </style>
            `)
            newWin.document.write(`
            <img class='banner' src='images/print-banner.png'>
            <img class='banner2' src='images/land-banner.png'>
            <div class='x'>${divToPrint.html()}</div>
            <img class='footer' src='images/print-footer.png'>
            <img class='footer2' src='images/land-footer.png'>
        `);
            newWin.document.close()
            newWin.print();
        },
        sendNow(){
            var g = this;
            $.post(api + '/admin/users/send-trips', {
                jwt: g.user.jwt,
                message: g.message,
                selected: g.selected?.join(",")
            }).then(function(r){
                alert(r.response, 100)
            })
        },
    }
}
</script>
<style>

</style>